import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/workspace/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "quick-start"
    }}>{`Quick Start`}</h1>
    <h2 {...{
      "id": "run-ocelot-locally"
    }}>{`Run Ocelot locally`}</h2>
    <p>{`The best way to learn how to use something, it is to start using it!`}</p>
    <p>{`Dig into how Ocelot works and how it can help you secure your application by running it locally.`}</p>
    <p>{`First create a folder to keep everything tidy. Let's call it `}<inlineCode parentName="p">{`ocelot-local`}</inlineCode>{`.`}</p>
    <p>{`Create a file in `}<inlineCode parentName="p">{`ocelot-local/db-creds.json`}</inlineCode>{` with the following contents`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
    "schemaConnectionString": "jdbc:postgresql://db/ocelot?user=ocelot&sslmode=disable&password=mypassword",
    "selectOnlyConnectionString": "jdbc:postgresql://db/ocelot?user=ocelot&sslmode=disable&password=mypassword",
    "upsertConnectionString": "jdbc:postgresql://db/ocelot?user=ocelot&sslmode=disable&password=mypassword"
}
`}</code></pre>
    <p>{`Then create a new file in `}<inlineCode parentName="p">{`ocelot-local/docker-compose.yaml`}</inlineCode>{` with the following contents:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`version: "3.9"

services:
  db:
    image: postgres
    environment:
      - POSTGRES_DB=ocelot
      - POSTGRES_USER=ocelot
      - POSTGRES_PASSWORD=mypassword
  ocelot:
    image: yanchware/ocelot:v2.3.2
    ports:
      - "8080:8080"
    environment:
      - SPRING_PROFILES_ACTIVE=postgres,local
      - OCELOT_DB_CREDENTIALS=\${DB_CREDS}
`}</code></pre>
    <p>{`Then run the following command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`DB_CREDS=$(cat db-creds.json) docker compose up -d
`}</code></pre>
    <p>{`You can test the application by attempting a logon with the root operator:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`curl http://localhost:8080/ocelot/logon\\?type\\=password -d '{"username":"root", "password":"<password from the output of the running service>"}' -H "Content-Type: application/json" -v
*   Trying ::1:8080...
* Connected to localhost (::1) port 8080 (#0)
> POST /ocelot/logon?type=password HTTP/1.1
> Host: localhost:8080
> User-Agent: curl/7.77.0
> Accept: */*
> Content-Type: application/json
> Content-Length: 58
>
* Mark bundle as not supporting multiuse
< HTTP/1.1 200 OK
< Set-Cookie: OSS=8779B835BD7D620F4B6EC3C064800E04667; Path=/; Domain=localhost; Max-Age=300; Expires=Sun, 16 Jan 2022 23:36:51 GMT; Secure; HttpOnly
< content-length: 0
<
* Connection #0 to host localhost left intact
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      